<app-admin-nav></app-admin-nav>
<section>
<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>
<mat-card>
  <mat-card-title> Payback : {{payback?.amount | currency:'NGN':'symbol-narrow'}}</mat-card-title>
  <mat-card-subtitle>{{payback?.person}} | {{payback?.name}}</mat-card-subtitle>
  <mat-card-content>
  </mat-card-content>

  <mat-card-footer>
    <small></small>
    <small>{{payback?.createdAt |date:'medium'}}</small>
  </mat-card-footer>
</mat-card>
<div class="space-between">

<button mat-raised-button (click)="confirm(payback._id)" color="primary">Confirm</button>
<button mat-button color='warn' class='del-button' (click)='delPayback()'>delete</button>

</div>
  </section>
  <button mat-raised-button (click)="back()" color="warn">&#8592; Back</button>

import { ExportExcelService } from './../services/export-excel.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { Transaction } from '../models/txn.model';
import { MatSort } from '@angular/material/sort';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit, OnDestroy {
  columnsToDisplay =['createdAt', 'person','name' ,'reason', 'amount', 'confirmedBy'];
  allTxns: Transaction[];
  lp;
  saves;
  borrows;
  paybacks;
  balance;
  todayDate = Date.now().toString();
  allSub: any;
  loading = false;
  dataForExcel = [];
  constructor(private data: DataService, private excelService: ExportExcelService) { }

  ngOnInit(): void {
    this.loading = true;
    this.allSub = this.data.alltxn()
      .pipe(finalize(() => this.loading = false))
    .subscribe((data: any) => {
      this.allTxns = data.txn;
      this.saves = (data.save).reduce((total, item) =>  total + item.amount, 0);
      this.borrows = (data.borrow).reduce((total, item) => total + item.amount, 0);
      this.paybacks = (data.payback).reduce((total, item) => total + item.amount, 0);
      this.balance = this.saves + this.paybacks - this.borrows;

    });
  }

  worksheet(): void {

    this.excelService.exportExcel(this.allTxns);
  }




  ngOnDestroy(): void{
    if(this.allSub){this.allSub.unsubscribe();}
  }




  }


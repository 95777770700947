import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { User } from '../models/user.model';
import { Transaction } from '../models/txn.model';
import { Save } from '../models/saving.model';
import { Borrow } from '../models/borrow.model';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy{
  user: User;
  todayDate = Date.now();
  unconfirmed;
  amountSave: number;
  lp;

  @Input() txn: Transaction;
  saves: Save[];
  borrows: Borrow[];
  amountBorrow: number;
  userSub: any;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private data: DataService) {



  }

  ngOnInit(): void {
      this.loading = true;
      const lp = this.route.snapshot.paramMap.get('lp');
      this.userSub = this.data.getUser()
        .pipe(
          tap(data =>{
            finalize(() => this.loading = false)
          })).subscribe({
        next: (data: any) => {
        this.user = data.user;
        this.txn = data.txn;
        this.saves = data.saves;
        this.borrows = data.borrows;


        this.amountSave = (this.saves).reduce((total, save) => {

          return total + Math.abs(save.amount);

        }, 0);

        this.amountBorrow = (this.borrows).reduce((total, borrow) => {

          return total + Math.abs(borrow.amount);

        }, 0);

        this.lp = this.user.lp;


      },
      error: (err) => console.log(err)});



  }
    clicktxn(t){

      this.router.navigateByUrl(`/user/${t.person}/edit/${t._id}`);

    }


    getTotal(transactions){

      transactions.reduce((total, amount) =>{
          return total + Math.abs(amount.amount);
      }, 0);
    }

    ngOnDestroy(): void {
      if(this.userSub){this.userSub.unsubscribe();}
        }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy{
  regSub: any;
  loginSub: any;
  hide: boolean;
  switchForm: boolean = true;
  constructor(
    private auth: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  error = '';
  person;
  loading = false;

  ngOnInit(): void {}

  register(newUser: NgForm) {
    this.loading = true;
    if (newUser.valid) {
      this.regSub = this.auth.registration(newUser.value)
        .pipe(finalize(() => this.loading = false))
      .subscribe(
        (data) => {
          this.person = data;
          this.switchForm = true;
          this.snackBar.open('Registration successful!', 'Done', { duration: 2000});
          this.router.navigateByUrl(`/`);
        },
        (error) => {

          this.error = error.error;
        }
      );
    } else {
      this.error = 'Check your inputs';
    }
  }
  changeForm(){
    this.switchForm = !this.switchForm;
  }

  login(user: NgForm) {
    this.loading = true;
    if (user.valid) {

      this.loginSub = this.auth.login(user.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (data: any) => {
          this.person = data;
          localStorage.setItem('x-tt-token', data.token);
          this.snackBar.open('Welcome!', `LP${this.person.user.lp}`, { duration: 3000 });
          this.router.navigateByUrl(`/user/${this.person.user.lp}`);
        },
        (err) => {
          this.error = err.error;
        }
      );
    } else {
      this.error = 'Kindly check your inputs';
    }
  }

  ngOnDestroy(): void{

    if(this.regSub){this.regSub.unsubscribe();}
    if(this.loginSub){this.loginSub.unsubscribe();}
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-usernav',
  templateUrl: './usernav.component.html',
  styleUrls: ['./usernav.component.css']
})
export class UsernavComponent implements OnInit {
  lp;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.lp = this.route.snapshot.paramMap.get('lp');
  }

  logout() {
    localStorage.removeItem('x-tt-token');
    this.router.navigateByUrl('/');
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-txn-not-clear',
  templateUrl: './txn-not-clear.component.html',
  styleUrls: ['./txn-not-clear.component.css']
})
export class TxnNotClearComponent implements OnInit, OnDestroy {
  saves: any;
  borrows: any;
  allSub: any;

  constructor(private router: Router, private data: DataService) { }

  ngOnInit(): void {
    this.allSub = this.data.alltxn()
      .subscribe((response: any) => {
        this.saves = response.save;
        this.borrows = response.borrow;
      }, (err) => {

      });
  }

  ngOnDestroy(): void{
    if(this.allSub){this.allSub.unsubscribe();}
  }

}

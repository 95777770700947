<mat-card [ngClass]=txn.reason.toLowerCase()>
  <mat-card-title><span></span>{{txn.reason}}:  {{txn.amount | currency: "NGN":"symbol-narrow" }}</mat-card-title>
  <mat-card-subtitle> LP{{txn.person}} | {{ txn.name}}</mat-card-subtitle>
  <mat-card-content *ngIf="display">
    <mat-checkbox color="primary" [checked]="txn?.confirm_guarantor1" disabled>Guarantor 1: LP{{txn?.guarantor1?.lp}} - {{txn?.guarantor1?.lname | uppercase }} {{ txn.guarantor1?.fname}} </mat-checkbox><br>
    <mat-checkbox color="primary" [checked]="txn?.confirm_guarantor2" disabled>Guarantor 2: LP{{txn?.guarantor2?.lp}} - {{txn?.guarantor2?.lname | uppercase }} {{ txn.guarantor2?.fname}}</mat-checkbox>
  </mat-card-content>
  <mat-card-footer>
    <span><small>Confirmed by {{ txn.confirmedBy}}</small></span>
    <span><small>{{txn.createdAt | date: 'medium'}}</small></span>
  </mat-card-footer>
</mat-card>

<app-admin-nav></app-admin-nav>

<section>
  <mat-card>
  <mat-card-title color="primary">{{txn?.reason}}: {{ txn?.amount | currency: "NGN":"symbol-narrow" }} </mat-card-title>
  <mat-card-subtitle>{{txn?.person}} | {{txn?.name}}</mat-card-subtitle>
  <mat-card-content>
   <mat-form-field appearance="fill">
    <mat-label for="confirmedAmount">Confirm amount</mat-label>
    <input type="number" name="amount" id="amount" matInput required ngModel #amount="ngModel">
    <span matSuffix>.00</span>
  </mat-form-field>
  </mat-card-content>
<mat-checkbox color="primary" [checked]="txn.confirm_guarantor1" disabled>Guarantor 1: LP{{txn.guarantor1.lp}} - {{txn.guarantor1.lname | uppercase }} {{ txn.guarantor1.fname}} </mat-checkbox><br>
<mat-checkbox color="primary" [checked]="txn.confirm_guarantor2" disabled>Guarantor 2: LP{{txn.guarantor2.lp}} - {{txn.guarantor2.lname | uppercase }} {{ txn.guarantor2.fname}}</mat-checkbox>

<mat-card-footer>
  <small></small>
  <small>{{txn?.createdAt | date: 'medium'}}</small>
</mat-card-footer>

</mat-card>

<div class="space-between">

  <button (click)="confirm(txn._id, amount)" mat-raised-button color="primary">Confirm</button>
    <button mat-button color='warn' class='del-button' (click)='delBorrow(txn._id)'>delete</button>

</div>
</section>
<button  (click)="back()" mat-raised-button color="warn"> &#8592; Back</button>

import { Injectable } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }

  exportExcel(mydata) {

    //Title, Header & Data
    const title = 'Employee Data';
    const header = ['Date & Time','Name', 'Person','Transaction Type',   'Dr', 'Cr' , 'Confirmed By' ];

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();

    let confirmedSheet =  workbook.addWorksheet('Confirmed Transactions');



    //Blank Row
    confirmedSheet.addRow(header);
    for (let x1 of mydata){
      let x2;
      if(x1.reason == 'Borrow'){
        x2 = Object.keys({createdAt: '',name: '', person: '10481', reason: 'Borrow',  amount: 45000, '': '' , confirmedBy: '10481'});
      }else{
        x2 = Object.keys({createdAt: '', name: '',  person: '10481', reason: 'Borrow', '': '', amount: 45000 , confirmedBy: '10481'});
      }

      let temp=[];
      for(let y of x2)

        {
          temp.push(x1[y]);

        }
        confirmedSheet.addRow(temp);
      }





    //Generate & Save Excel File
   let fname='TrendyThrift'

//add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname +'-'+'Transactions'+ Date.now() +'.xlsx');
    });
  }


  exportUser(users){

    //Title, Header & Data
    const title = 'Employee Data';
    const header = ['LP','FIrstname', 'Lastname', 'Phone number', 'Bank', 'Account number', 'Next of Kin', 'Kin phone number' ];

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();

    let confirmedSheet =  workbook.addWorksheet('Confirmed Transactions');



    //Blank Row
    confirmedSheet.addRow(header);
    for (let x1 of users){
      let x2 = Object.keys({lp: '10481', fname: '', lname: '', phone: '10481', bank: 'Borrow',  account:  '', kin: '' , kin_phone: '10481'});


      let temp=[];
      for(let y of x2)

        {
          temp.push(x1[y]);

        }
        confirmedSheet.addRow(temp);
      }





    //Generate & Save Excel File
   let fname='TrendyThrift'

//add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname +'-'+ 'Users' +Date.now() +'.xlsx');
    });

  }
}

<app-admin-nav></app-admin-nav>

<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>
<section>
  <h1>TrendyThrift</h1>
  <mat-card *ngIf="balance">
    <mat-card-title>Balance : {{balance | currency:'NGN':'symbol-narrow'}}</mat-card-title>
    <mat-card-content>Total Saves: {{saves | currency:'NGN':'symbol-narrow'}}</mat-card-content>
    <mat-card-content>Total Paybacks: {{paybacks | currency:'NGN':'symbol-narrow'}}</mat-card-content>
    <mat-card-content>Total Borrows: {{borrows | currency:'NGN':'symbol-narrow'}}</mat-card-content>
    <mat-card-footer>{{ todayDate | date: 'medium'}}</mat-card-footer>
  </mat-card>
  <span class='btn-body'>
    <span class='left-button'>
      <a mat-raised-button color="warn" [routerLink]="['/admin','pending']"
        routerLinkActive="router-link-active">Pending</a>
    </span>
  </span>
  <app-transaction [txn]="t" *ngFor="let t of txns"></app-transaction>

</section>

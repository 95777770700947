<app-usernav></app-usernav>
<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>
<section *ngIf="user">
  <button mat-button color="warn" (click)="changeState()">{{!state ? 'Change password' :  'Edit user profile'}}</button>

<h2 >{{state ? 'Change password' :  'Edit user profile'}}</h2>
<ng-template [ngIf]="state">
  <form #changePassword="ngForm" (ngSubmit)="change(changePassword)">

    <mat-card-content class="warn">{{error}}</mat-card-content><br/>

    <mat-form-field appearance="outline">
      <mat-label for="password">Password</mat-label>
      <input matInput type="password" id="password" name="password" placeholder="Password" ngModel autocomplete="new-password" required>
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field><br>
    <mat-form-field appearance="outline">
      <mat-label for="confirmpassword">Confirm Password</mat-label>
      <input matInput type="password" id="confirmpassword" name="confirmpassword" placeholder="Confirm Password" autocomplete="confirm-password" ngModel required>
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field><br>
    <button type="submit" mat-raised-button color="primary">Change password</button>
  </form>
</ng-template>
<ng-template [ngIf]="!state">

<form #profile="ngForm" (ngSubmit)="edit(profile)">

    <mat-card-content class="warn">{{ error }}</mat-card-content>

  <mat-form-field appearance="outline">
    <mat-label for="lp">LP number</mat-label>
    <input matInput type="text" id="lp" name="lp" placeholder="LP number" [(ngModel)]="user.lp"  disabled required>
  </mat-form-field><br>
  <mat-form-field appearance="outline">
    <mat-label for="fname">First name</mat-label>
    <input matInput type="text" id="fname" name="fname" placeholder="First name" [(ngModel)]="user.fname" required>
  </mat-form-field><br>
  <mat-form-field appearance="outline">
    <mat-label for="lname">Last name</mat-label>
    <input matInput type="text" id="lname" name="lname" placeholder="Last name" [(ngModel)]="user.lname" required>
  </mat-form-field><br>
  <mat-form-field appearance="outline">
    <mat-label for="phone">Phone number</mat-label>
    <input matInput type="tel" id="phone" name="phone" placeholder="Phone number" [(ngModel)]="user.phone" required>
  </mat-form-field><br>
  <mat-form-field appearance="outline">
    <mat-label for="email">Email</mat-label>
    <input matInput type="email" id="email" name="email" placeholder="E-mail" [(ngModel)]="user.email" required>
  </mat-form-field><br>


  <mat-form-field appearance="outline">
    <mat-label for="bank">Bank</mat-label>
    <input matInput type="text" id="bank" name="bank" placeholder="Bank name"  autocomplete="Bank" [(ngModel)]="user.bank">
  </mat-form-field><br>


  <mat-form-field appearance="outline">
    <mat-label for="account">Account number</mat-label>

    <input matInput type="text" id="account" name="account" placeholder="Account number"  autocomplete="Account number" [(ngModel)]="user.account">
  </mat-form-field><br>


  <mat-form-field appearance="outline">

  <mat-label for="kin">Next of kin</mat-label>
    <input matInput type="text" id="kin" name="kin" placeholder="Next of kin"  autocomplete="next of kin" [(ngModel)]="user.kin">
  </mat-form-field><br>

  <button type="submit" mat-raised-button color="primary">Save </button>

</form>

</ng-template>
<button type="button" (click)="back()" mat-raised-button color="warn">&#8592; Back</button>
</section>


<app-nav-bar></app-nav-bar>
<section>
<h2>Who we are</h2>
<p>We are Trendsetters who take advantage of our pool of fund and expertise to give financial leverage for members' set goal.</p>








<h2>How it works </h2>

<ul>
<li>Members contribute monthly to the purse.</li>
<li>Members who need loan will apply and such application will be moderated by the Executives.</li>
<li>Disbursement is not later than 48hours after all requirements have been met.</li>
</ul>
</section>

<app-usernav></app-usernav>
<h2 class="center">Thrift forms</h2>
<mat-error>{{error}}</mat-error>

<section>
  <h2>Trendythrift Forms</h2>
<mat-accordion>
  <mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Save
    </mat-panel-title>
    <mat-panel-description>Save the future</mat-panel-description>
  </mat-expansion-panel-header>
  <form #saver ="ngForm" (ngSubmit)="save(saver)">
    <mat-form-field appearance="outline">
      <mat-label for="saveAmount">Amount to Save</mat-label>
      <span matPrefix>₦&nbsp;</span>
      <span matSuffix>.00</span>
      <input type="number" name="amount" id="amountSave" matInput required ngModel #amount="ngModel">

    </mat-form-field><br>
    <button type="submit" mat-raised-button color="primary" [disabled]="saver.invalid"> Save</button>
  </form>
</mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Loan Request
      </mat-panel-title>
      <mat-panel-description>Borrow tomorrow</mat-panel-description>
    </mat-expansion-panel-header>

    <form #borrower="ngForm" (ngSubmit)="borrow(borrower)">
    <mat-form-field appearance="outline">
      <mat-label for="amount">Amount to Borrow</mat-label>
      <span matPrefix>₦&nbsp;</span>
      <span matSuffix>.00</span>
      <input type="number" name="amount" id="amount" matInput  required ngModel #amount="ngModel" required>

    </mat-form-field><br>
    <mat-form-field appearance="outline">
      <mat-label for="firstLP">1. Gurarantor LP</mat-label>
      <mat-select placeholder="First Guarantor LP" name="guarantor1" matInput required ngModel  #guarantor1="ngModel" required>
        <mat-option *ngFor="let user of users" [value]="user._id">LP{{ user.lp}} - {{ user.lname | uppercase }} {{ user.fname}}</mat-option>
      </mat-select>
    </mat-form-field><br>

    <mat-form-field appearance="outline">
      <mat-label for="secondLP">2. Guarantor LP</mat-label>
      <mat-select placeholder="Second Guarantor LP" name="guarantor2" matInput required ngModel  #guarantor2="ngModel" required>
        <mat-option *ngFor="let user of users" [value]="user._id">LP{{ user.lp}} - {{ user.lname | uppercase }} {{ user.fname}}</mat-option>
      </mat-select>
    </mat-form-field><br>
    <span><mat-checkbox color="primary" [checked]="checked"  ngModel name="checked" #checked="ngModel" required>Yes, I agree to the <a class="underline" [routerLink]="['/terms']" routerLinkActive="router-link-active" >terms & conditions</a></mat-checkbox></span><br>

    <button type="submit" mat-raised-button color="primary" [disabled]="borrower.invalid"> Borrow </button>
  </form>
  </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Payback Loan
        </mat-panel-title>
        <mat-panel-description>Payback yesterday</mat-panel-description>
      </mat-expansion-panel-header>

      <form #repay="ngForm" (ngSubmit)="loanrepay(repay)">
        <mat-form-field appearance="outline">
          <mat-label for="amount">Amount to Repay</mat-label>
          <span matPrefix>₦&nbsp;</span>
          <span matSuffix>.00</span>
          <input type="number" name="amount" id="amount" matInput required ngModel #amount="ngModel">

        </mat-form-field><br>
        <button type="submit" mat-raised-button color="primary" [disabled]="repay.invalid">Payback Loan</button>
      </form>
    </mat-expansion-panel>
</mat-accordion>


<button (click)="back()" mat-raised-button color="warn" class="margin-1"> &#8592; Back</button>

</section>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {
  error ='';
  adminSub: any;
  loading = false;
  hide: boolean;

  constructor(private auth: AuthService, private router: Router) {

  }

  ngOnInit(): void {
  }

  getAdmin(admin: NgForm){
    this.loading = true;
    this.adminSub = this.auth.adminLogin(admin.value)
      .pipe(finalize(() => this.loading = false))
    .subscribe((response: any) => {
      localStorage.setItem('x-tt-token', response.token);
      this.router.navigateByUrl(`/admin/dashboard`);
    },(err: any) => {
      this.error = err.statusText;
    })
  }

  ngOnDestroy(): void {
    if(this.adminSub){
    this.adminSub.unsubscribe();
  }
}


}

<app-admin-nav></app-admin-nav>

<section>
  <mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
  </mat-progress-spinner>

<ng-template [ngIf]="status">
  <mat-card-title class="center">Registered Users</mat-card-title>

  <button mat-raised-button color='warn' (click)='users(allUsers)'>Download User details</button><span>*as excel file</span>
<table mat-table [dataSource]="allUsers" class="mat-elevation-z8">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let user; columns: columnsToDisplay"></tr>

  <ng-container matColumnDef="lp">
    <th mat-header-cell *matHeaderCellDef="lp"> LP </th>
    <td class="underline" mat-cell *matCellDef="let user" (click)="select(user.lp)">{{user.lp}}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef="name"> Name </th>
    <td mat-cell *matCellDef="let user"> {{user.fname}} {{user.lname}} </td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef="phone"> Phone number </th>
    <td mat-cell *matCellDef="let user"> {{user.phone}} </td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef="role"> Roles </th>
    <td mat-cell *matCellDef="let user"> {{user.role == 'queen' ? 'Administrator' : 'User'}}</td>
  </ng-container>



</table>

</ng-template>
<ng-template [ngIf]="!status">

  <mat-card *ngFor="let user of theUser">
    <mat-card-title>{{user.lname | uppercase }} {{user.fname}}( {{user.role == 'queen' ? 'Administrator' : 'User'}})</mat-card-title>
    <mat-card-subtitle>
      <p>Phone number: {{user.phone}}</p>
      <p>Email: {{user.email}}</p>
      <p *ngIf="user.bank">Bank name: {{user.bank}}</p>
      <p *ngIf="user.account">Account number: {{user.account}}</p>
      <p *ngIf="user.kin">Next of Kin: {{ user.kin}}</p>
      <p *ngIf="user.kin_phone">Kin Phone number: {{user.kin_phone}}</p>
      <p>Registered: {{user.createdAt | date:'medium'}}</p>
      </mat-card-subtitle>

    <mat-card-actions class="space-between">
      <button mat-raised-button color="primary" (click)="resetpassword(user._id)">
        Reset Password
      </button>
      <button mat-raised-button color="warn" (click)="changeRole(user)">Change Role</button>
    </mat-card-actions>

  </mat-card>
  <button mat-raised-button color="warn" (click)="switchBack()">&#8592; Back</button>
</ng-template>
</section>

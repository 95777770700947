
<mat-card>
  <mat-card-title>{{guarantor.person}} | {{guarantor.amount | currency:'NGN':'symbol-narrow'}}</mat-card-title>
  <mat-card-subtitle>Guarantor</mat-card-subtitle>
  <mat-card-content>
    <mat-checkbox color="primary" [checked]="guarantor.confirm_guarantor1" disabled>Guarantor 1: LP{{guarantor.guarantor1.lp}} - {{guarantor.guarantor1.lname | uppercase }} {{ guarantor.guarantor1.fname}} </mat-checkbox><br>
    <mat-checkbox color="primary" [checked]="guarantor.confirm_guarantor2" disabled>Guarantor 2: LP{{guarantor.guarantor2.lp}} - {{guarantor.guarantor2.lname | uppercase }} {{ guarantor.guarantor2.fname}}</mat-checkbox>
  </mat-card-content>

  <mat-card-footer>
    <span></span>
    <small>{{guarantor.createdAt |date:'medium'}}</small>


  </mat-card-footer>
</mat-card>

<app-usernav></app-usernav>
<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>
<section>



<mat-card>
<mat-card-title>Welcome {{user?.fname}} {{user?.lname}},</mat-card-title>
<mat-card-subtitle>{{ todayDate | date: 'medium' }}</mat-card-subtitle>
<mat-card-content>Total Savings: {{(amountSave || 0.0 )| currency: "NGN":"symbol-narrow" }} </mat-card-content>
<mat-card-content>Total Borrows: {{(amountBorrow || 0.0)| currency: "NGN":"symbol-narrow"}} </mat-card-content>
<mat-card-footer>
  <span></span>
  <small class="edit" ><a [routerLink]="['/user/', lp, 'edituser']" routerLinkActive="active" >Edit LP{{user?.lp}} Profile</a></small>
</mat-card-footer>

</mat-card>
<h2 class="center" *ngIf="txn">Confirmed Transactions</h2>
<h2 class="center" *ngIf="!txn">Confirmed Transactions</h2>
  <app-transaction
[txn]="t"
*ngFor="let t of txn">
null

</app-transaction>



</section>

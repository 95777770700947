import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from '../models/txn.model';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.css']
})
export class SaveComponent implements OnInit {
  @Input() txn: Transaction;
  constructor(private router: Router, private data: DataService) { }

  ngOnInit(): void {
  }





}

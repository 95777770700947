import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from '../models/txn.model';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-loan-pay-back',
  templateUrl: './loan-pay-back.component.html',
  styleUrls: ['./loan-pay-back.component.css']
})
export class LoanPayBackComponent implements OnInit {
  @Input() txn: Transaction;
  constructor(private router: Router, private data: DataService) { }

  ngOnInit(): void {
  }

  delPayback(id){
    console.log(id);
    this.data.getPayBack(id);
  }
}

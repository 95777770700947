import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

private urlapi = environment.baseUrl;
// private urlapi = `https://trendythrift.herokuapp.com/api/v1`;


  role = 'user';
  constructor(private http: HttpClient, private router: Router, public helper: JwtHelperService) {

  }


  login(user): Observable<any> {
    return this.http.post(`${this.urlapi}/login`, user);
  }
  logout(): void {
    localStorage.removeItem('x-tt-token');
    this.router.navigateByUrl('/');
  }

  isAdmin(): boolean {
    return (this.role === 'queen') ? true : false;
  }
  isLoggedIn(): boolean {
    const token = this.getToken();
    return !this.helper.isTokenExpired(token) ? true : false;
  }
  adminLogin(admin): Observable<any> {
    return this.http.post(`${this.urlapi}/admin/signin`, admin);
  }

  getToken(): string {
    const token = localStorage.getItem('x-tt-token');
    return token;
  }


  getPayLoad(): object {
    const payload = this.helper.decodeToken(this.getToken());
    return payload;
  }


  registration(newUser) {
    return this.http.post(`${this.urlapi}/register`, newUser);

  }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationStrategy } from '@angular/common';
import { User } from '../models/user.model';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent implements OnInit {
  panelOpenState = false;
  user;
  users:User[];
  error;
  saveSub: any;

  constructor(
    private data: DataService,
    private auth: AuthService,
    private location: LocationStrategy,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    this.data.getUsers().subscribe({
      next: (data) => {
        console.log(data)
        this.users = data.users;
      },
      error: (err: any)=>{
        console.log(err);
      }

    })


  }

    back(){
    this.location.back();
  }
    save(adminSaver: NgForm){
    if(adminSaver.valid){
      this.saveSub = this.data.adminSave(adminSaver.value)
      .subscribe((data: any) => {
      this.snackBar.open('Transaction successful!', 'Done', { duration: 2000});
      this.location.back();
      },(error) => {

          this.snackBar.open('Wrong LP number', 'Error', { duration: 2000});
        })


    }else{
        console.log('Wrong LP number')
      this.snackBar.open('Transaction not successful!', 'Done', { duration: 2000});

    }
  }


}

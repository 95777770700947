import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { LocationStrategy } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-payback',
  templateUrl: './edit-payback.component.html',
  styleUrls: ['./edit-payback.component.css']
})
export class EditPaybackComponent implements OnInit, OnDestroy {
  id;
  payback;
  paySub: any;
  confirm$: Subscription;
  pa: any;
  loading = false;
  delPaybackSub: any;
  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private location: LocationStrategy,
    private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.id = this.route.snapshot.paramMap.get('id');
    this.paySub = this.data.getPayBack(this.id)
    .pipe(finalize(() => this.loading = false))
    .subscribe((data: any) => {
      this.payback = data.payback;

    })

  }
   back() {
     this.location.back();
   }
  confirm(id){
    this.confirm$ = this.data.confirmPayback(id)
    .subscribe({
      next: (data: any) => {
        console.log(data)
       this.snackBar.open('Transaction confirmed!', 'Done', { duration: 2000});
    },
    error: (err: any) => {
      console.log(err)
      this.snackBar.open('Not confirmed!', 'Error', { duration: 2000});
    }})

  }

    delPayback() {
    this.delPaybackSub = this.data.delPayBack(this.id)
    .subscribe((data) => {
      this.snackBar.open('Transaction deleted!', 'Done', { duration: 2000});
      this.location.back();
    }, (err: any) => {

    })
  }
  ngOnDestroy(): void{
    if(this.paySub){
    this.paySub.unsubscribe();
}
  if(this.delPaybackSub){
    this.delPaybackSub.unsubscribe();
  }
    if(this.confirm$){
    this.confirm$.unsubscribe();
    }
  }
}

<app-nav-bar></app-nav-bar>
<mat-card class='center'>
  <mat-card-title>Super TrendyThrift</mat-card-title>
<form #admin="ngForm" (ngSubmit)="getAdmin(admin)">
  <mat-card-content class="warn">{{ error }}</mat-card-content>
  <mat-form-field appearance="outline">
    <mat-label for="email">e-mail</mat-label>
    <input matInput type="email" id="email" name="email" autocomplete="email" placeholder="e-mail" ngModel required>
  </mat-form-field><br>
  <mat-form-field appearance="outline">
    <mat-label for="password">password</mat-label>
    <input matInput type="password" id="password" name="password" placeholder="Password" ngModel [type]= "hide ? 'text': 'password' "  required>
    <p (click)="hide = !hide">show password</p>
  </mat-form-field><br>
  <button type="submit" mat-raised-button color="primary">Login <mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
  </mat-progress-spinner></button>
</form>
</mat-card>

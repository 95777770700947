import { Component, OnInit, Input } from '@angular/core';
import { Borrow } from '../models/borrow.model';
import { Guarantor } from '../models/guarantor.model';

@Component({
  selector: 'app-guarantor',
  templateUrl: './guarantor.component.html',
  styleUrls: ['./guarantor.component.css']
})
export class GuarantorComponent implements OnInit {
  @Input() guarantor: Borrow;
  constructor() {

   }

  ngOnInit(): void {

  }

}

<app-usernav></app-usernav>
<section>
  <mat-card>
  <mat-card-title> {{guarantor.amount | currency:'NGN':'symbol-narrow'}}</mat-card-title>
  <mat-card-subtitle>Guarantor | {{guarantor.name}}</mat-card-subtitle>
  <mat-card-content>
    <mat-checkbox color="primary" [checked]="guarantor.confirm_guarantor1" disabled>Guarantor 1: LP{{guarantor.guarantor1.lp}} - {{guarantor.guarantor1.lname | uppercase }} {{ guarantor.guarantor1.fname}} </mat-checkbox><br>
    <mat-checkbox color="primary" [checked]="guarantor.confirm_guarantor2" disabled>Guarantor 2: LP{{guarantor.guarantor2.lp}} - {{guarantor.guarantor2.lname | uppercase }} {{ guarantor.guarantor2.fname}}</mat-checkbox>

  </mat-card-content>
  <mat-checkbox [checked]="checked"  ngModel name="checked" #checked="ngModel"></mat-checkbox>
  <span>     I agree to payback if he/she default, other terms & condition apply</span>

  <mat-card-footer>{{guarantor.createdAt |date:'medium'}}</mat-card-footer>
</mat-card>
<button mat-raised-button  color="basic" (click)="back()"> Cancel </button>
<button mat-raised-button [disabled]="!checked.value" color="primary" (click)="confirm(guarantor)"> Confirm </button>
</section>


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  private urlapi = environment.baseUrl;
  // private urlapi = `https://trendythrift.herokuapp.com/api/v1`;

  user;
  lp: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthService) {
    this.user = this.auth.getPayLoad();
    this.lp = this.user.lp;


  }

  getUser(): Observable<any> {
    return this.http.get(`${this.urlapi}/user/${this.lp}`);
  }

  editUser(user): Observable<any> {
    return this.http.patch(`${this.urlapi}/user/${this.lp}/editUser`, { user });
  }

  getLpUser(lp): Observable<any> {
    // get only user details
    return this.http.get(`${this.urlapi}/user/${lp}/getUser`);
  }

  save(txn): Observable<any> {
    return this.http.post(`${this.urlapi}/user/${this.lp}/save`, txn);
  }

  pending(txn): Observable<any> {
    return this.http.post(`${this.urlapi}/user/${this.lp}/pending`, txn);
  }
  borrow(txn): Observable<any> {
    return this.http.post(`${this.urlapi}/user/${this.lp}/borrow`, txn);
  }

  payback(txn): Observable<any> {
    return this.http.post(`${this.urlapi}/user/${this.lp}/payback`, txn);
  }

  changePassword(password) {
    return this.http.patch(`${this.urlapi}/user/${this.lp}/changepassword`, {password})
  }

  confirmGuarantor(id, g): Observable<any> {
    return this.http.patch(`${this.urlapi}/user/${this.lp}/loan/${id}/confirm`, { g });
  }
  getABorrow(id): Observable<any> {
    return this.http.get(`${this.urlapi}/user/${this.lp}/borrow/${id}`);
  }

  deleteSave(id): Observable<any> {
    return this.http.delete(`${this.urlapi}/user/${this.lp}/save/${id}`);

  }

  deleteBorrow(id): Observable<any> {
    return this.http.delete(`${this.urlapi}/user/${this.lp}/borrow/${id}`);

  }

  getPending(): Observable<any> {
    return this.http.get(`${this.urlapi}/user/${this.lp}/pending`);
  }

  alltxn(): Observable<any> {
    return this.http.get(`${this.urlapi}/admin`);
  }

  getUsers(): Observable<any> {
    return this.http.get(`${this.urlapi}/admin/users`);
  }

  allUncleartxn(): Observable<any> {

    return this.http.get(`${this.urlapi}/admin/unclear`);

  }

  getASave(id): Observable<any> {
    return this.http.get(`${this.urlapi}/admin/save/${id}`);
  }
  getPayBack(id): Observable<any> {
    return this.http.get(`${this.urlapi}/admin/payback/${id}`);
  }
  confirmPayback(id): Observable<any> {
    const lp = this.user.lp;
     console.log(this.user)
    return this.http.patch(`${this.urlapi}/admin/payback/${id}/confirm`, { lp });
  }
  confirmSave(id): Observable<any> {
    const lp = this.user.lp;
    return this.http.patch(`${this.urlapi}/admin/save/${id}/confirm`, { lp });
  }

  confirmBorrow(id, confirmedAmount): Observable<any> {
    const lp = this.user.lp;
    return this.http.patch(`${this.urlapi}/admin/borrow/${id}/confirm`, { lp, confirmedAmount });
  }
  resetpassword(id): Observable<any> {
    return this.http.patch(`${this.urlapi}/admin/resetpassword`, { id });
  }

  changeRole(id): Observable<any> {
    return this.http.patch(`${this.urlapi}/admin/changerole`, { id });
  }

  delSaves(id): Observable<any> {
    return this.http.delete(`${this.urlapi}/admin/save/${id}`);
  }

  delBorrow(id): Observable<any> {
    return this.http.delete(`${this.urlapi}/admin/borrow/${id}`);
  }

  delPayBack(id): Observable<any> {
    return this.http.delete(`${this.urlapi}/admin/payback/${id}`);
  }

  adminSave(save): Observable<any> {
    return this.http.post(`${this.urlapi}/admin/save`, save);
  }


}

<app-admin-nav></app-admin-nav>
<section>
<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>


  <mat-card *ngIf="balance">
    <mat-card-title>Balance : {{balance | currency:'NGN':'symbol-narrow'}}</mat-card-title>
    <mat-card-content>Total Saves: {{saves | currency:'NGN':'symbol-narrow'}}</mat-card-content>
    <mat-card-content>Total Paybacks: {{paybacks | currency:'NGN':'symbol-narrow'}}</mat-card-content>
    <mat-card-content>Total Borrows: {{borrows | currency:'NGN':'symbol-narrow'}}</mat-card-content>
    <mat-card-footer>{{ todayDate | date: 'medium'}}</mat-card-footer>
  </mat-card>
  <button mat-raised-button color='warn' (click)='worksheet()'>Download Transactions</button><span>*as excel file</span>
  <mat-card-title class="center">Confirmed Transactions</mat-card-title>
  <input matInput type="search" #lp name="lp"  placeholder="Search with User LP number or Name" />


  <table mat-table [dataSource]="allTxns | search: lp.value">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let user; columns: columnsToDisplay"></tr>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef="createdAt" mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let user"> {{user.createdAt | date: 'medium'}} </td>
      </ng-container>

    <ng-container matColumnDef="person">
      <th mat-header-cell *matHeaderCellDef="person" mat-sort-header> LP </th>
      <td mat-cell *matCellDef="let user"> {{user.person}} </td>
    </ng-container>

      <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef="name" mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let user"> {{user.name}} </td>
    </ng-container>


    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef="reason" mat-sort-header> Reason </th>
      <td mat-cell *matCellDef="let user"> {{user.reason}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef="amount" mat-sort-header> Amount </th>
      <td mat-cell *matCellDef="let user"> {{user.amount | currency:'NGN':'symbol-narrow'}} </td>
    </ng-container>

      <ng-container matColumnDef="confirmedBy">
        <th mat-header-cell *matHeaderCellDef="confirmedBy" mat-sort-header> Confirmed By </th>
        <td mat-cell *matCellDef="let user"> {{user.confirmedBy}} </td>
      </ng-container>




  </table>


</section>

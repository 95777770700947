import { Location } from '@angular/common';
import { Component, OnInit, Output, Inject } from '@angular/core';
import { DataService } from '../services/data.service';
import { ExportExcelService } from '../services/export-excel.service';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../models/user.model';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  allUsers: User[];
  columnsToDisplay = ['lp', 'name', 'phone',  'role'];
  dialogConfig = new MatDialogConfig();
  loading = false;
  changeRoleSub: Subscription;
  resetpassword$: Subscription;
  theUser: User[];
  status:boolean = true;
  constructor(
    private data: DataService,
    private location: Location,
    private excelService: ExportExcelService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loading = true;
    this.data.getUsers()
      .pipe(finalize(() => this.loading = false))
    .subscribe((data: any) => {
      this.allUsers = data.users;
    });
  }


  changeRole(a) {
   this.changeRoleSub =  this.data.changeRole(a._id)
      .subscribe({
        next: (thedata: any) => {

          this.snackBar.open('Role successfully changed!', 'Done', { duration: 2000});

    },
  error: (err) => {
    this.snackBar.open('Error changing role!', 'Error', { duration: 2000});
  }});
  this.ngOnInit();
  this.switchBack();

  }

  resetpassword(id){
    this.resetpassword$ = this.data.resetpassword(id).subscribe({
      next: (data: any) =>{
        console.log(data);
        this.snackBar.open('Password reset successful!', 'Done', { duration: 2000});
        this.ngOnInit();
    },
  error: (err) => {
    console.log(err)
    this.snackBar.open('Error resetting password!', 'Error', { duration: 2000});
  }});
   }


  select(lp){
    console.log(lp)
    this.theUser = this.allUsers.filter((user) => {
     return user.lp === lp;
    })
    this.status = !this.status;
    console.log(this.theUser)
  }


  switchBack(){
    this.status = !this.status;
    this.ngOnInit();

  }

  users(allUsers){
    this.excelService.exportUser(allUsers)

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.changeRoleSub) {
      this.changeRoleSub.unsubscribe();
    }

    if(this.resetpassword$){
      this.resetpassword$.unsubscribe();
    }
  }
}



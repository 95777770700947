import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { LocationStrategy } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../models/user.model';

@Component({
  selector: 'app-thriftforms',
  templateUrl: './thriftforms.component.html',
  styleUrls: ['./thriftforms.component.css']
})
export class ThriftformsComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  error;
  loanSub: any;
  saveSub: any;
  borrowSub: any;
  users$: Subscription;
  users: User[];
  @Input() checked: boolean;
  constructor(private data: DataService, private auth: AuthService, private location: LocationStrategy,private snackBar: MatSnackBar) {



   }

  ngOnInit(): void {
    this.users$ = this.data.getUsers()
                    .subscribe(users =>{
                      this.users = users.users;
                    })
                    ;

  }

  save(saver: NgForm){
    if(saver.valid){
      this.saveSub = this.data.save(saver.value)
      .subscribe((data: any) => {
        this.snackBar.open('Transaction successful!', 'Done', { duration: 2000});
        this.location.back();
      })
    }
  }

  borrow(borrower: NgForm){
    if(!this.checked){
      if(borrower.valid){
      this.borrowSub = this.data.borrow(borrower.value)
      .subscribe((data: any) => {
        this.snackBar.open('Transaction successful!', 'Done', { duration: 2000});
        this.location.back();
      });

    }else{
        this.error = "Check your inputs";
    }

    }else{
          this.error = "Kindly agree to the terms and condition";
    }



  }

  loanrepay(repay: NgForm){
    if(repay.value && repay.valid){
     this.loanSub =  this.data.payback(repay.value)
      .subscribe((data: any)=> {
        this.snackBar.open('Transaction successful!', 'Done', { duration: 2000});
        this.location.back();
      })
    }
  }

  back(){
    this.location.back();
  }

  ngOnDestroy(): void{
    this.users$.unsubscribe();
    if(this.borrowSub){this.borrowSub.unsubscribe();}
    if(this.saveSub){this.saveSub.unsubscribe();}
    if(this.loanSub){this.loanSub.unsubscribe();}
  }
}

<app-admin-nav></app-admin-nav>
<section>
<mat-card>
  <mat-card-title color="primary">{{txn?.reason}}: {{ txn?.amount | currency: "NGN":"symbol-narrow" }}</mat-card-title>
  <mat-card-subtitle>{{ txn?.person }} | {{txn?.name}}</mat-card-subtitle>

  <mat-card-footer><small>{{txn?.createdAt | date: 'medium'}}</small></mat-card-footer>
</mat-card>

<div class="space-between">

  <button (click)="confirm(txn?._id)" mat-raised-button color="primary">Confirm</button>
  <button mat-button color='warn' class='del-button' (click)='delSave(txn?._id)'>delete</button>
</div>
</section>
<button (click)="back()" mat-raised-button color="warn"> &#8592; Back</button>

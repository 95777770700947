import { Component, OnInit, Output, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { Guarantor } from '../models/guarantor.model';
import { Save } from '../models/saving.model';
import { Borrow } from '../models/borrow.model';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Payback } from '../models/payback.model';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.css']
})
export class PendingComponent implements OnInit, OnDestroy {
  guarantors: Guarantor[];
  lp;
  saves: Save[];
  borrows: Borrow[];
  paybacks: Payback [];
  @Output() guarantor;
  pendSub: any;
  loading = false;
  constructor(private data: DataService, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.pendSub = this.data.getPending()
    .pipe(finalize(() => this.loading = false))
    .subscribe((data: any) => {
      this.saves = data.save;
      this.guarantors = data.guarantor;
      this.borrows = data.borrow;
      this.paybacks = data.payback;

    });

  }

  confirm(guarantor){
    this.router.navigateByUrl(`/user/${this.data.lp}/edit/guarantor/${guarantor._id}`);
  }

  ngOnDestroy(): void{
    this.pendSub.unsubscribe();
  }

}

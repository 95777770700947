import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Borrow } from '../models/borrow.model';
import { LocationStrategy } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  guarantor: Borrow;
  id;
  borrowSub: any;
  confirmSub: any;
  check: boolean = true;

  constructor(
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private location: LocationStrategy,
    private snackBar: MatSnackBar) {
    this.id = this.route.snapshot.paramMap.get('id');

  }

  ngOnInit(): void {

    this.borrowSub = this.data.getABorrow(this.id)
    .subscribe((data: { borrow: Borrow}) => {
      this.guarantor = data.borrow;

    })


  }

  confirm(guarantor){
    console.log(this.data)
    let my = '';
    if (guarantor.guarantor1.lp == this.data.lp) {
        my = 'guarantor1';
    } else if(guarantor.guarantor2.lp == this.data.lp){
      my = 'guarantor2';
    }else{

    }
    this.confirmSub = this.data.confirmGuarantor(guarantor._id, my)
    .subscribe({
      next:(data: any) =>{
        console.log(data)
      this.snackBar.open('Loan Guaranteed', 'Done', { duration: 2000});
      this.location.back()

        },
    error: (err) =>{
      this.snackBar.open('Loan not guaranteed', 'Error', { duration: 2000});
      this.location.back()
        }});


  }

  getGuarantor(guarantor){

  }

  back(){
    this.location.back();
  }

  ngOnDestroy(): void {

    this.borrowSub.unsubscribe();


    if(this.confirmSub){
    this.confirmSub.unsubscribe();
  }
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-unconfirm',
  templateUrl: './unconfirm.component.html',
  styleUrls: ['./unconfirm.component.css']
})
export class UnconfirmComponent implements OnInit {
  details;
  saves: any;
  borrows: any;
  paybacks: any;
  unclearSub: any;
  loading = false;
  constructor(private data: DataService, private router: Router, private location: LocationStrategy) { }

  ngOnInit(): void {
    this.loading= true;
   this.unclearSub =  this.data.allUncleartxn()
    .pipe(finalize(() => this.loading = false))
      .subscribe((data: any) => {
        console.log(data)
        this.saves = data.save;
        this.borrows = data.borrow;
        this.paybacks = data.payback;
      }, (err: any) => {
        console.log(err)
      });

  }

  editPayback(details) {
    this.router.navigateByUrl(`/admin/edit/payback/${details._id}`);
  }

  editSave(details) {
    this.router.navigateByUrl(`/admin/edit/save/${details._id}`);
  }

  editBorrow(details) {
    this.router.navigateByUrl(`/admin/edit/borrow/${details._id}`);
  }

  back(){
    this.location.back();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.unclearSub){
      this.unclearSub.unsubscribe();
    }
  }
}

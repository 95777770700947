import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-are',
  templateUrl: './we-are.component.html',
  styleUrls: ['./we-are.component.css']
})
export class WeAreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

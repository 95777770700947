<app-nav-bar></app-nav-bar>
<section>


<h2>TRENDYTHRIFT CLUB POLICY</h2>

<ol type='1'>

<li>The financial year shall be from December to November( when WAEC pays November Salary)</li>

<li>The Bank Account for all transactions of the TrendyThrift club <br/>(co-managed by two of the overseeing Executives)
  <ul type="square">
  <li>Account number: 3141130931</li>
  <li>Bank: First Bank of Nigeria</li>
  <li>Account name: Obiakor Augusta Nkechi and Opemuyi Opeyemi Oyinda</li>
</ul>

  </li>


<li>Loan applications will be approved based on the available fund applicant credit rating.</li>
<li>Additional loan, before settling the previous one will, only be granted to those whose credit worthiness have been established.</li>
<li>Interest rate is 5% for the approved loan repayment period as graded and detailed in this policy.</li>
<li>Defaulters (of repayment period) shall pay 2% interest rate monthly on balance outstanding.</li>
<li>Savings used to offset loan will not attract any profit for the period concerned.</li>
<li>Guarantors are liable to pay in full (with defaulting charge where applicable) in case of a default member guaranteed, as at close of business for the year.</li>
<li>Repayment period shall be as follows:

  <ul type="square">
    <li>₦1,000.00 - ₦300,000.00 (Duration: 3months)</li>
    <li>₦300,001.00 - ₦499,999.00 ( Duration: 4 months) </li>
    <li>₦500,000.00 and above(Duration: 6 months)</li>
  </ul>
</li>
<li>For the avoidance of doubt, please note that the stated repaymentperiod may be shortened if a loan is taken close to the end of the financial year. Such laons must be fully repaid with the applicable basic 5% interest before or by the close of business for that year.</li>

</ol>


</section>



<section class='form'>
<app-nav-bar></app-nav-bar>

<div class='flex'>
<mat-card>
  <aside style="text-align: center; padding-top: 15px">
  <h1 class="big center">TrendyThrift</h1>
<h1>
  Plan.Save.Borrow
</h1>


</aside>
    <section class="login-board">
      <p class="center" *ngIf="switchForm">New user? <span class="bold" (click)="changeForm()">Register</span></p>
      <p class="center" *ngIf="!switchForm">Are you registered? Click here to <span class="bold" (click)="changeForm()">sign in</span></p>
      <section class="login" *ngIf="switchForm">
        <form #user="ngForm" (ngSubmit)="login(user)">
          <mat-card-content class="warn">{{ error }}</mat-card-content>
          <mat-label for="lp">LP number</mat-label>
          <mat-form-field appearance="outline">
            <span matPrefix>LP</span>
          <input matInput type="text" id="lp" name="lp" placeholder="LP number" autocomplete="on" ngModel required>
          </mat-form-field>
                  <mat-label for="password">Password</mat-label>
          <mat-form-field appearance="outline">

          <input matInput id="password" name="password" placeholder="Password" autocomplete="current-password" ngModel  [type]= "hide ? 'text': 'password' " required>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field><br>
          <button type="submit" mat-raised-button color="primary" [disabled]="user.invalid">
            Login <mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
            </mat-progress-spinner></button>

        </form>
      </section>
      <section class="registration" *ngIf="!switchForm">
        <form #newUser="ngForm" (ngSubmit)="register(newUser)">
          <mat-card-content class="warn">{{ error }}</mat-card-content>
          <mat-label for="fname">First name</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" id="fname" name="fname" placeholder="First name" autocomplete="given-name"  ngModel required>
          </mat-form-field>
          <mat-label for="lname">Last name</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" id="lname" name="lname" placeholder="Last name" autocomplete="family-name" ngModel required>
          </mat-form-field>
          <mat-label for="lp">LP number</mat-label>
          <mat-form-field appearance="outline">
            <span matPrefix>LP</span>
            <input matInput type="text" id="lp" name="lp" placeholder="LP number" autocomplete="on" ngModel required>
          </mat-form-field>
          <mat-label for="phone">Phone number</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="tel" id="phone" name="phone" placeholder="Phone number" autocomplete="tel-local" ngModel required>
          </mat-form-field>
          <mat-label for="email">Email</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="email" id="email" name="email" placeholder="E-mail" autocomplete="email" ngModel required>
          </mat-form-field>

          <mat-label for="bank">Bank</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" id="bank" name="bank" placeholder="Bank name"  autocomplete="Bank" ngModel>
          </mat-form-field>

          <mat-label for="account">Account number</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" id="account" name="account" placeholder="Account number"  autocomplete="Account number" ngModel>
          </mat-form-field>


          <mat-label for="kin">Next of kin</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" id="kin" name="kin" placeholder="Next of kin"  autocomplete="next of kin" ngModel>
          </mat-form-field>


          <mat-label for="kin_phone">Next of Kin Phone</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" id="kin_phone" name="kin_phone" placeholder="Next of Kin phone number"   ngModel>
          </mat-form-field>

          <mat-label for="password">Password</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="password" id="newpassword" name="password" placeholder="Password" autocomplete="new-password" ngModel required>
          </mat-form-field>
          <mat-label for="confirmpassword">Confirm Password</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="password" id="confirmpassword" name="confirmpassword" placeholder="Confirm Password" autocomplete="current-password" ngModel required>
          </mat-form-field><br>
          <span>
            <mat-checkbox color="primary" [checked]="true" required>Yes, I agree to the <a class="underline" [routerLink]="['/terms']"
                routerLinkActive="router-link-active">terms & conditions</a></mat-checkbox>
          </span><br>
          <button type="submit" mat-raised-button color="primary" [disabled]="newUser.invalid">
            Register <mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
            </mat-progress-spinner></button>

        </form>
      </section>

    </section>
    </mat-card>


    <section class="img">

        <figure>
          <img src="../../assets/office.png">
          <figcaption>
            <p>You save...</p>
            <p>We borrow...</p>
            <p>We pay you for saving...</p>
          </figcaption>
        </figure>

    </section>

  </div>
    </section>



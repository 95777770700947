
<mat-card>
  <mat-card-title> Payback : {{txn.amount | currency:'NGN':'symbol-narrow'}}</mat-card-title>
  <mat-card-subtitle>{{txn.person}} | {{txn.name}}</mat-card-subtitle>
  <mat-card-content>
  </mat-card-content>

  <mat-card-footer>
    <small></small>
    <small>{{txn.createdAt |date:'medium'}}</small>
  </mat-card-footer>
</mat-card>


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.css']
})
export class AdminNavComponent implements OnInit {
  lp;
  constructor(private route: ActivatedRoute, private auth: AuthService) {

   }

  ngOnInit(): void {
    this.lp = this.route.snapshot.paramMap.get('lp');
  }

  logout(){
    this.auth.logout();
  }

}

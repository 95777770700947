<app-admin-nav></app-admin-nav>

<section>
  <h2 class="center">Pending Items</h2>
<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>
<mat-accordion *ngIf="saves">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Pending Saves
      </mat-panel-title>
      <mat-panel-description>{{saves?.length}}</mat-panel-description>
    </mat-expansion-panel-header>
    <app-save [txn]="save" *ngFor="let save of saves" (click)="editSave(save)"></app-save>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Loan Request
      </mat-panel-title>
      <mat-panel-description>{{borrows?.length}}</mat-panel-description>
    </mat-expansion-panel-header>
    <app-borrow [txn]="borrow" *ngFor="let borrow of borrows" (click)="editBorrow(borrow)"></app-borrow>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Payback Loan
      </mat-panel-title>
      <mat-panel-description>{{paybacks?.length}}</mat-panel-description>
    </mat-expansion-panel-header>
    <app-loan-pay-back [txn]="payback" *ngFor="let payback of paybacks" (click)="editPayback(payback)"></app-loan-pay-back>

  </mat-expansion-panel>
</mat-accordion>
<app-admin-form></app-admin-form>
<button mat-raised-button (click)="back()" color="warn">&#8592; Back</button>

</section>

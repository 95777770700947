import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsComponent } from './terms/terms.component';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { WeAreComponent } from './we-are/we-are.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';



@NgModule({
  imports: [
    RouterModule.forRoot([
    {
      path: 'home',
      component: HomeComponent
    },
    {
      path: 'weare',
      component: WeAreComponent
    },
    {
      path: 'terms',
      component: TermsComponent
    },
    {
      path: 'admin',
      component: AdminComponent
    },
    {
      path: 'user/:lp',
      loadChildren: () => import('./user/user-routing').then(m => m.UserModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'admin',
      loadChildren: () => import('./admin/admin-routing').then(m => m.AdminModule),
      canActivate: [AuthGuard, AdminGuard]
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'

    },
    {
      path: '**',
      redirectTo: 'home'
    }
  ])],
  exports: [RouterModule]
})
export class AppRoutingModule { }

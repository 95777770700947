import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Transaction } from '../models/txn.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-admin-txn',
  templateUrl: './admin-txn.component.html',
  styleUrls: ['./admin-txn.component.css']
})
export class AdminTxnComponent implements OnInit, OnDestroy{
  lp;
  saves;
  borrows;
  paybacks;
  details;
  balance;
  todayDate = Date.now().toString();

  txns: any;
  @Output() transaction = new EventEmitter<Transaction>();
  allSub: any;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private data: DataService) {
    this.lp = this.route.snapshot.paramMap.get('lp');
     }

    ngOnInit(): void {
      this.loading = true;

      this.allSub = this.data.alltxn()
        .pipe(finalize(() => this.loading = false))
        .subscribe((data: any) => {
          this.txns = data.txn;
          this.saves = (data.save).reduce((total, item) => total + item.amount, 0);
          this.borrows = (data.borrow).reduce((total, item) => total + item.amount, 0);
          this.paybacks = (data.payback).reduce((total, item) => total + item.amount, 0);
          this.balance = this.saves + this.paybacks - this.borrows;
        }, (err: any) => {
            console.log(err)
        });
  }

  ngOnDestroy(): void {

    if(this.allSub){
    this.allSub.unsubscribe();
  }
}

}

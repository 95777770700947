import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(list: any[], searchItem: string): any {
    return list
      ? list.filter(
          (item: any) =>
            item.person.match(String(searchItem)) ||  item.name.toLowerCase().match(String(searchItem.toLowerCase()))
        )
      : [];
  }

}

<mat-card>
  <mat-card-title>{{txn.reason}} : {{txn.amount | currency:'NGN':'symbol-narrow'}}</mat-card-title>
  <mat-card-subtitle>{{txn.person}} | {{ txn.name }} </mat-card-subtitle>
  <mat-card-content>
    <p><mat-checkbox [checked]="txn.confirm_guarantor1" disabled></mat-checkbox> : LP{{txn.guarantor1.lp}} - {{txn.guarantor1.lname | uppercase }} {{ txn.guarantor1.fname}} </p>
    <p><mat-checkbox [checked]="txn.confirm_guarantor2" disabled></mat-checkbox> : LP{{txn.guarantor2.lp}} - {{txn.guarantor2.lname | uppercase }} {{ txn.guarantor2.fname}} </p>
  </mat-card-content>
  <mat-card-footer>
    <span></span>
    <small>{{txn.createdAt |date:'medium'}}</small>

  </mat-card-footer>

</mat-card>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {
  error = '';
  lp: string;
  user: User;
  hide: boolean = false;
  userSub: Subscription;
  editSub: any;
  loading = false;
  state: boolean = false;
  changePasswordSub: Subscription;
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private data: DataService,
    private auth: AuthService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.userSub = this.data.getLpUser(this.data.lp)
      .pipe(finalize(() => this.loading = false))
      .subscribe((data: any) => {
        this.user = data.user[0];
      });


  }

  edit(profile) {
        this.editSub = this.data.editUser(profile.value)
          .subscribe((data: any) => {
            this.auth.logout();
          });
  }

  changeState() {
    this.state = !this.state;
  }

  change(newPassword) {

    const { password ,  confirmpassword } = newPassword.value;
    if(password !== confirmpassword ){
      this.error = 'Confirm password not match'
    }else{
      this.changePasswordSub = this.data.changePassword(password).subscribe({
        next: (data) => {
          this.snackBar.open('Password successfully changed', 'Success',{ duration: 2000 });
          this.auth.logout();
        },
        error: (error) => {
          this.snackBar.open('There is an Error!', 'Error!',{ duration: 2000 });
        }
    })
  }
}

  back() {
    this.location.back();
  }

  ngOnDestroy(): void {
    if (this.editSub) {
      this.editSub.unsubscribe();
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

}

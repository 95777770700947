import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { DataService } from '../services/data.service';
import { Transaction } from '../models/txn.model';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-edit-save',
  templateUrl: './edit-save.component.html',
  styleUrls: ['./edit-save.component.css']
})
export class EditSaveComponent implements OnInit, OnDestroy {
  txnid;
  txn: Transaction;
  lp;
  saveSub: any;
  delSaveSub: any;
  confirmSub: any;
  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private location: LocationStrategy) {


  }

  ngOnInit(): void {
    this.txnid = this.route.snapshot.paramMap.get('id');

    this.saveSub = this.data.getASave(this.txnid)
    .subscribe((data : any) => {

        this.txn = data.save;
    },(err: any) => {

    })
  }

    delSave(id) {
    this.delSaveSub = this.data.delSaves(id)
    .subscribe((data) => {
      this.location.back();
    }, (err: any) => {

    })

  }


  confirm(id){
    this.confirmSub = this.data.confirmSave(id)
    .subscribe((data) => {

      this.location.back();
    },(err: any) => {

    })

  }

  back(){
    this.location.back();
  }

  ngOnDestroy(): void {
    if (this.saveSub){this.saveSub.unsubscribe();}
    if (this.confirmSub){this.confirmSub.unsubscribe();
    if(this.delSaveSub){this.delSaveSub.unsubscribe()}}
  }
}

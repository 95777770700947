<app-usernav></app-usernav>
<mat-progress-spinner *ngIf="loading" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
</mat-progress-spinner>
<section>
        <h2 class="center">Pending transactions</h2>

        <span matBadge="{{saves?.length}}" *ngIf="saves?.length" matBadgeOverlap="false">Saves</span>

        <app-save [txn]="save" *ngFor="let save of saves"></app-save>

        <span matBadge="{{borrows?.length}}" *ngIf="borrows?.length" matBadgeOverlap="false">Borrows</span>

        <app-borrow [txn]="borrow" *ngFor="let borrow of borrows"></app-borrow>

        <span matBadge="{{guarantors?.length}}" *ngIf="guarantors?.length" matBadgeOverlap="false">Guarantor</span>
        <app-guarantor [guarantor]="guarantor" *ngFor="let guarantor of guarantors" (click)="confirm(guarantor)"></app-guarantor>

          <span matBadge="{{paybacks?.length}}" *ngIf="paybacks?.length" matBadgeOverlap="false">Paybacks</span>

            <app-loan-pay-back [txn]="payback" *ngFor="let payback of paybacks"></app-loan-pay-back>

</section>

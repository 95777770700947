
Cash at Hand
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Loan Request
      </mat-panel-title>
      <mat-panel-description>
        Not yet Paid
      </mat-panel-description>

    </mat-expansion-panel-header>

  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Savings
      </mat-panel-title>
      <mat-panel-description>
        Not yet confirmed
      </mat-panel-description>
    </mat-expansion-panel-header>


    <app-save *ngFor="let save of saves" [txn]="save"></app-save>


  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Borrow
      </mat-panel-title>
      <mat-panel-description>
        Not yet confirmed
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-borrow *ngFor="let borrow of borrows" [txn]="borrow"></app-borrow>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Payback Loan
      </mat-panel-title>
      <mat-panel-description>
        Not yet confirmed
      </mat-panel-description>
    </mat-expansion-panel-header>


  </mat-expansion-panel>
</mat-accordion>

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-borrow',
  templateUrl: './borrow.component.html',
  styleUrls: ['./borrow.component.css']
})
export class BorrowComponent implements OnInit {
  @Input() txn;
  constructor(private router: Router, private data: DataService) { }

  ngOnInit(): void {
  }

  delBorrow(id) {
    this.data.delSaves(id);
  }

}

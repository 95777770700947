import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { LocationStrategy } from '@angular/common';
import { IfStmt } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-borrow',
  templateUrl: './edit-borrow.component.html',
  styleUrls: ['./edit-borrow.component.css']
})
export class EditBorrowComponent implements OnInit, OnDestroy {
  txnid;
  txn;
  borrowSub: any;
  confirmSub: any;
  delBorrowSub: any;
  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private location: LocationStrategy,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.txnid = this.route.snapshot.paramMap.get('id');
    this.borrowSub = this.data.getABorrow(this.txnid)
    .subscribe((data: any) => {
      this.txn = data.borrow;
    },(err: any) => {

    })
  }

    confirm(id, amount){
      const confirmedAmount = amount.value;
      console.log(confirmedAmount);
      this.confirmSub = this.data.confirmBorrow(id, confirmedAmount)
      .subscribe((data) => {
         this.snackBar.open('Transaction confirmed!', 'Done', { duration: 2000});
        this.location.back();
       }, (err: any) => {

       })

  }

  back() {
    this.location.back();
  }

    delBorrow(id) {
    this.delBorrowSub = this.data.delBorrow(id)
    .subscribe((data) => {
       this.snackBar.open('Transaction deleted!', 'Done', { duration: 2000});
      this.location.back();
    }, (err: any) => {

    })
  }

  ngOnDestroy(): void {
    if(this.borrowSub){
    this.borrowSub.unsubscribe();
  }

    if(this.delBorrowSub){
      this.delBorrowSub.unsubscribe();
    }
    if(this.confirmSub){
    this.confirmSub.unsubscribe();
}
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Transaction } from '../models/txn.model';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-edit-transaction',
  templateUrl: './edit-transaction.component.html',
  styleUrls: ['./edit-transaction.component.css']
})
export class EditTransactionComponent implements OnInit {
  id;
  @Input() selected: Transaction;



  constructor( private route: ActivatedRoute, private data: DataService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
  }




}

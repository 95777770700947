<h2 class="center">Thrift forms</h2>
<mat-error>{{error}}</mat-error>
<mat-accordion>
  <mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Create Saves
    </mat-panel-title>
    <mat-panel-description>Create saves</mat-panel-description>
  </mat-expansion-panel-header>
  <form #adminSaver="ngForm" (ngSubmit)="save(adminSaver)">
    <mat-form-field appearance="outline">
      <mat-label for="saveAmount">Amount to Save</mat-label>
      <span matPrefix>₦&nbsp;</span>
      <span matSuffix>.00</span>
      <input type="number" name="amount" id="amount" matInput required ngModel #amount="ngModel">

    </mat-form-field><br>
    <mat-form-field appearance="outline">
      <mat-label for="saverLP">Saver</mat-label>
      <mat-select placeholder="Saver LP" name="saverLP" matInput required ngModel  #saverLP="ngModel" required>
        <mat-option *ngFor="let user of users" [value]="user.lp">LP{{ user.lp}} - {{ user.lname | uppercase }} {{ user.fname | titlecase }}</mat-option>
      </mat-select>
     </mat-form-field><br>
     <p>**Note that transaction will be confirmed immediately.</p>
    <button type="submit" mat-raised-button color="primary">Save</button>
  </form>
</mat-expansion-panel>
</mat-accordion>


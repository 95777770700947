import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { UserComponent } from './user/user.component';
import { AdminComponent } from './admin/admin.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module/material.module';
import { HomeComponent } from './home/home.component';
import { AuthInterceptor } from './httpinterceptor.service';
import { AuthService } from './services/auth.service';
import { JwtModule } from '@auth0/angular-jwt';
import { WeAreComponent } from './we-are/we-are.component';
import { ThriftformsComponent } from './thriftforms/thriftforms.component';
import { UsernavComponent } from './usernav/usernav.component';
import { DataService } from './services/data.service';
import { BorrowComponent } from './borrow/borrow.component';
import { SaveComponent } from './save/save.component';
import { TransactionComponent } from './transaction/transaction.component';
import { EditUserComponent } from './user/edit-user/edit-user.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { EditTransactionComponent } from './edit-transaction/edit-transaction.component';
import { AdminTxnComponent } from './admin-txn/admin-txn.component';
import { AdminNavComponent } from './admin-nav/admin-nav.component';
import { TxnNotClearComponent } from './txn-not-clear/txn-not-clear.component';
import { GuarantorComponent } from './guarantor/guarantor.component';
import { UnconfirmComponent } from './unconfirm/unconfirm.component';
import { TermsComponent } from './terms/terms.component';
import { EditSaveComponent } from './edit-save/edit-save.component';
import { EditBorrowComponent } from './edit-borrow/edit-borrow.component';
import { UsersComponent } from './users/users.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { PendingComponent } from './pending/pending.component';
import { LoanPayBackComponent } from './loan-pay-back/loan-pay-back.component';
import { EditPaybackComponent } from './edit-payback/edit-payback.component';
import { AdminFormComponent } from './admin-form/admin-form.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { AdminModule } from './admin/admin.module';
import { SearchPipe } from './admin/search.pipe';


export function getToken() {
  return localStorage.getItem('x-tt-token');
}

@NgModule({
  declarations: [
    SearchPipe,
    AppComponent,
    NavBarComponent,
    UserComponent,
    AdminComponent,
    FooterComponent,
    HomeComponent,
    WeAreComponent,
    UsernavComponent,
    ThriftformsComponent,
    BorrowComponent,
    SaveComponent,
    TransactionComponent,
    EditUserComponent,
    ConfirmComponent,
    EditTransactionComponent,
    AdminTxnComponent,
    AdminNavComponent,
    TxnNotClearComponent,
    GuarantorComponent,
    UnconfirmComponent,
    TermsComponent,
    EditSaveComponent,
    EditBorrowComponent,
    UsersComponent,
    TransactionsComponent,
    PendingComponent,
    LoanPayBackComponent,
    EditPaybackComponent,
    AdminFormComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: ['localhost:4200/']
      }
    }),
    MaterialModule,
    RouterModule,
    MatPasswordStrengthModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  @Input() txn;
  @Input() selected;
  display: boolean;
  constructor() {
  }

  ngOnInit(): void {
    this.display = this.txn.reason === 'Borrow';

  }

  borrow(){
    return (this.txn.reason === 'Borrow') ? true : false;
  }



}
